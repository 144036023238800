.bg-blue {
  background: $color-light-blue;
}
.btn-c-login {
  margin-top: 5px;
  width: 100px;
}
.nav-header {
  a {
    color: #061323 !important;
  }
}

@media (min-width: 768px) {
  .nav-user-setting {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.dropdown-user-setting {
  a:hover {
    background: $color-light-blue;
    color: black;
  }
}

.dropdown-user-setting {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 0px !important;

  a {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.navbar-toggler {
  background: #fff;
}
