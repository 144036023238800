.footer {
  background: linear-gradient(to top left, #031020 47%, #101d2c 47%);
  hr {
    background: $color-orange;
    padding: "0.3rem";
    height: 0.5rem;
    border-radius: 0.2rem;
  }
  div .social {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    background: #fff;
    margin-left: 1rem;
  }
  .logo {
    // width: 20rem;
    height: 5rem;
    text-transform: uppercase;
    text-align: center;
    padding-top: 1rem;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}
