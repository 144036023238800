#root {
  height: 100%;
}

@font-face {
  font-family: NotoSanRegular;
  src: url(../../assets/font/noto_sans_regular.ttf);
}

@font-face {
  font-family: NotoSanBold;
  src: url(../../assets/font/noto_sans_bold.ttf);
}

@font-face {
  font-family: NotoSanSemiBold;
  src: url(../../assets/font/noto_sans_semi_bold.ttf);
}

@font-face {
  font-family: NotoSanSemiBlack;
  src: url(../../assets/font/noto_sans_black.ttf);
}

@font-face {
  font-family: NotoSanMedium;
  src: url(../../assets/font/noto_sans_medium.ttf);
}

@font-face {
  font-family: PoppinsRegular;
  src: url(../../assets/font/poppins_regular.ttf);
}

@font-face {
  font-family: PoppinsBold;
  src: url(../../assets/font/poppins_bold.ttf);
}

@font-face {
  font-family: PoppinsBlack;
  src: url(../../assets/font/poppins_black.ttf);
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url(../../assets/font/poppins_semi_bold.ttf);
}

@font-face {
  font-family: PoppinsMedium;
  src: url(../../assets/font/poppins_medium.ttf);
}

@font-face {
  font-family: NautilusPompilius;
  src: url(../../assets/font/nautilus.otf);
}
@font-face {
  font-family: Sacramento;
  src: url(../../assets/font/sacramento_regular.ttf);
}

@font-face {
  font-family: Edo;
  src: url(../../assets/font/edo.ttf);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 100%;
}

body {
  box-sizing: border-box;
  font-family: "PoppinsRegular", "NotoSanRegular";
}
