.constainer-user-setting {
  min-height: 92vh !important;
  border: none !important;
  .contend {
    margin: 24px 16px;
    padding: 24px;
    background: #fff;
    height: 10rem !important;
  }
  // custom Antd
  .ant-layout {
    background: #e6f4f4 !important;
    margin-top: 1rem !important;
    border: none !important;
  }
  .ant-layout-sider-children {
    background: #e6f4f4 !important;
  }
  .sidbar {
    background: #e6f4f4 !important;
  }
  .ant-layout.ant-layout-has-sider > .ant-layout {
    background: #e6f4f4 !important;
  }
  .ant-menu-item {
    color: black !important;
    border: none !important;
  }
  .ant-menu-item a:hover {
    color: black !important;
    text-decoration: none !important;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: none !important;
    color: black !important;
  }
  .active {
    background: $color-light-blue !important;
    color: black;
    border-radius: 0.5rem;
  }
}
