.dasboard-container {
  background: #e8e8e8;
  .header-tab {
    padding-bottom: 2rem;
    .btn-tab-info {
      padding-bottom: 1.7rem;
      border-radius: 0rem;
      letter-spacing: 0.1rem;
      outline: none;
      cursor: pointer;
    }
    .btn-tab-register {
      cursor: pointer;
    }
    .activ-tab {
      border-bottom: 0.5rem solid $color-light-blue !important;
    }
  }
}
.progress-status {
  .icon-progress {
    margin-left: 1rem;
    font-size: 1.5rem;
  }
  .success-progress {
    color: $color-light-blue;
  }
  .padding-progress {
    margin-left: 7rem;
  }
}

.my-dashboard-header {
  border-top: 0.1rem solid $color-light-blue;
  border-bottom: 0.1rem solid $color-light-blue;
}
.box-card {
  border-radius: 0.5rem;
  background: $color-light-blue;
}
.ant-progress-success-bg,
.ant-progress-bg {
  background: $color-light-blue;
}
.ant-progress-status-success .ant-progress-bg {
  background: $color-light-blue;
}
.ant-progress-status-success .ant-progress-text{
  color: $color-light-blue !important;
}
