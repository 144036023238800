.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}
.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}
.u-margin-top-huge {
  margin-top: 10rem !important;
}
.hide {
  display: none !important;
}
.text-deep-blue {
  color: #091525 !important;
}
.bg-deep-blue {
  background: #091525 !important;
}
.active-menu span {
  border-bottom: 3px solid #41bfd0;
}

.menu-nav {
  .nav-link > span {
    color: #fff;
  }
}
