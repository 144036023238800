// COLOR
$color-light-blue: #00BDC6;
$color-orange: #FBB623;
$color-red: #F63841;
$color-black: #111D2C;

// COLORS
// $color-primary: #55c57a;
// $color-primary-light: #7ed56f;
// $color-primary-dark: #28b485;

// $color-secondary-light: #ffb900;
// $color-secondary-dark: #ff7730;

// $color-tertiary-light: #2998ff;
// $color-tertiary-dark: #5643fa;

// $color-grey-light-1: #f7f7f7;
// $color-grey-light-2: #eee;

$color-grey-dark: #777;
// $color-grey-dark-2: #999;
// $color-grey-dark-3: #333;

$color-white: #fff;
// $color-black: #000;

// FONT
$default-font-size: 1.6rem;

$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px
