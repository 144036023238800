.modal-custom {
  .ant-modal-body {
    padding: 0px !important;
  }
  .ant-modal-content {
    background-color: #fff0 !important;
  }
  .ant-modal-close-x {
    display: none !important;
  }
}
