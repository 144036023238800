a {
  text-decoration: none !important;
}
.view-course-button-customer {
  color: white !important;
  margin-top: 50px;
  width: 260px;
  height: 40px;
  font-family: "PoppinsSemiBold", "NotoSanSemiBold";
  background-color: #fbb623;
  font-size: 16px;
  border-width: 0px;
  border-radius: 0px;
  box-shadow: 2px 3px 1px 1px #88888838;
  margin: auto;
}

.img-container{
  height: 100vh;
  width: 100%;
  position: relative;
  object-fit: cover;
}

.blog-quote{
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-20%, -50%);
  margin: auto !important;
  @media (max-width: 1000px) {
    transform: translate(0%, 0%);
    left: 0%;
    margin: auto !important;
  }
}