/**
1 vender
2 theme
3 abstract
4 base
5 component
6 layout
7 page
*/

@import '~antd/dist/antd.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~font-awesome/css/font-awesome.min.css';
@import './vendors/antd';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

//@import '~slick-carousel/slick/slick.css';
//@import '~slick-carousel/slick/slick-theme.css';

// @import "./vendors/antd";
@import './abstracts/variables';
@import './abstracts/functions';
@import './abstracts/mixins';

@import './base/animations';
@import './base/base';
@import './base/typography';
@import './base/utilities';

// @import "./components/bg-video";

@import './layout/footer';
@import './layout/header';
@import './layout/navigation';

@import './pages/home';
@import './pages/signin';
@import './pages/user-setting';
@import './pages/register';
@import './pages/_mydashboard';
@import './pages/contact';

.pointer {
  cursor: pointer;
}
