.container-register {
  box-shadow: 0 0 1rem;
  margin-top: 10rem !important;
  margin-bottom: 5rem !important;
  padding: 2rem 4rem 3rem 4rem;
  max-width: 450px !important;
  .img-register {
    z-index: -1;
    margin-top: -15rem;
  }
  .tab {
    .tab-footer {
      .btn-tab {
        background: $color-orange;
        padding: 0.5rem 3rem 0.5rem 3rem;
        border: none;
        color: #fff;
        font-weight: bold;
        margin: auto;
        outline: none;
      }
      .btn-tab-back {
        padding: 0.6rem 3rem 0.6rem 3rem;
        border: none;
        color: black;
        font-weight: bold;
        margin: auto;
        outline: none;
        background-color: #eee;
        cursor: pointer;
      }
      .btn-tab-save {
        background: $color-light-blue;
        padding: 0.5rem 3rem 0.5rem 3rem;
        border: none;
        color: #fff;
        font-weight: bold;
        margin: auto;
        outline: none;
      }
    }
  }
}
.header-tab {
  border-bottom: 2px solid rgb(223, 224, 224);
  padding-bottom: 2rem;

  .btn-tab-info {
    padding-bottom: 1.7rem;
    border-radius: 0rem;
    letter-spacing: 0.1rem;
    outline: none;
  }
  .btn-tab-register {
    padding-bottom: 1.7rem;
    border-radius: 0rem;
    letter-spacing: 0.1rem;
    outline: none;
  }
  .activ-tab {
    border-bottom: 0.5rem solid $color-light-blue;
  }
  .btn-back {
    border-bottom: 0.5rem solid $color-light-blue;
  }
}
.conten-success-register {
  // margin: 1rem;
}
.btn-register-login {
  border: 1px solid black;
  outline: none;
  padding: 0.3rem 1.5rem 0.3rem 1.5rem;
}
.ant-select-selection--single {
  height: 100%;
}

@media (max-width: 1000px) {
  .img-register {
    display: none;
  }
}
@media (max-width: 1000px) {
  .container-register {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
}

@media (max-width: 460px) {
  .container-register {
    padding: 1rem 2rem 1.5rem 2rem;
  }
}
.modal-customer-layout {
  .ant-modal-content {
    position: relative;
    background-color: none !important;
    background: none !important;
    background-clip: 0px !important;
    border: 0px !important;
    border-radius: 0px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    pointer-events: auto;
  }
}
