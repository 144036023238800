.certificate-title {
  text-align: center;
  margin-top: 4rem;
  h6 {
    font-family: Poppins;
    font-size: 14.49px !important;
    span {
      font-family: Sacramento;
      font-size: 20.7px !important;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.title {
  text-align: center;
  h5 {
    font-weight: bold;
    font-family: Poppins;
    font-size: 20px;
  }
}
.logo-impact-hub {
  margin-top: 3rem;
  img {
    width: 100.58px;
    height: 100.58px;
  }
}

.certify-that {
  margin-top: 1.5rem;

  text-align: center;
  h6 {
    font-family: Poppins;
    font-size: 15px;
  }
}

.certify-for {
  margin-top: 1.5rem;
  text-align: center;
  h1 {
    font-size: 36px;
    font-family: NautilusPompilius;
  }
}
.refer-to {
  margin-top: 1.5rem;
  text-align: center;
  h1 {
    font-size: 26px;
    font-family: Poppins;
    font-weight: bold;
  }
}

.description {
  text-align: center;
  p {
    font-size: 12px;
    font-family: Poppins;
  }
}

.signature {
  .stamp {
    height: 111.09px;
    width: 111.09px;
    z-index: 1;
    position: absolute;
    margin-left: 5rem;
    margin-top: 4rem;
  }
  margin-top: 0.7rem;
  .logo {
    width: 196.66px;
    height: 134.36px;
  }
}

.certify-by {
  text-align: center;
  p {
    font-size: 12px;
    font-family: Poppins;
    span {
      font-size: 10;
      font-family: Poppins;
    }
  }
}

.footer-certificate {
  .footer-left {
    width: 296px;
    float: left;
    p {
      margin-left: 3rem;
      font-family: Poppins;
      font-size: 10.39px;
      span {
        font-family: Poppins;
        font-size: 10.39px;
        color: #004c83;
      }
    }
  }
  .footer-right {
    width: 296px;
    float: left;
    p {
      margin-right: 3rem;
      font-family: Poppins;
      font-size: 10.39px;
      span {
        font-family: Poppins;
        font-size: 10.39px;
        color: #004c83;
      }
    }
  }
}
// style for print pdf
@media print {
  .certificate-title {
    text-align: center;
    margin-top: 6rem;
    h6 {
      font-family: Poppins;
      font-size: 25px !important;
      span {
        font-family: Sacramento;
        font-size: 30px !important;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .title {
    text-align: center;
    h5 {
      font-weight: bold;
      font-family: Poppins;
      font-size: 35px;
    }
  }

  .logo-impact-hub {
    margin-top: 5rem;
    img {
      width: 170px;
      height: 170px;
    }
  }

  .certify-that {
    margin-top: 3rem;
    text-align: center;
    h6 {
      font-family: Poppins;
      font-size: 36px;
    }
  }

  .certify-for {
    margin-top: 1.5rem;
    text-align: center;
    h1 {
      font-size: 66px;
      font-family: NautilusPompilius;
    }
  }

  .refer-to {
    margin-top: 3rem;
    text-align: center;
    h1 {
      font-size: 56px;
      font-family: Poppins;
      font-weight: bold;
    }
  }

  .description {
    text-align: center;
    p {
      font-size: 26px;
      font-family: Poppins;
    }
  }

  .signature {
    .stamp {
      height: 200px;
      width: 200px;
      z-index: 1;
      position: absolute;
      margin-left: 5rem;
      margin-top: 4rem;
    }
    margin-top: 0.7rem;
    .signature {
      width: 300px;
      height: 260px;
    }
  }

  .certify-by {
    text-align: center;
    p {
      font-size: 26px;
      font-family: Poppins;
      span {
        font-size: 10;
        font-family: Poppins;
      }
    }
  }

  .footer-certificate {
    .footer-left {
      width: 50%;
      float: left;
      p {
        margin-left: 3rem;
        font-family: Poppins;
        font-size: 20px;
        span {
          font-family: Poppins;
          // font-size: 10.39px;
          font-size: 20px;
          color: #004c83;
        }
      }
    }
    .footer-right {
      width: 50%;
      float: left;
      p {
        margin-right: 3rem;
        font-family: Poppins;
        font-size: 20px;
        span {
          font-family: Poppins;
          font-size: 20px;
          color: #004c83;
        }
      }
    }
  }
}
