.ff-bold {
  font-family: "PoppinsSemiBold", "NotoSanSemiBold" !important;
}
.ff-normal {
  font-weight: normal !important;
}
.ff-medium {
  font-family: "PoppinsMedium", "NotoSanMedium" !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-35 {
  font-size: 35px !important;
}
.fs-50 {
  font-size: 50px !important;
}
.lh-200 {
  line-height: 200% !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
