.cover-img-login {
  z-index: 1;
  position: absolute;
  right: -4rem;
  bottom: 0rem;
}
.container-login {
  min-height: 95vh !important;
  input.ant-input.mb-1.input-login-custom::placeholder {
    color: black;
  }
  input.ant-input::placeholder {
    color: black;
  }
  .input-login-custom {
    background: $color-light-blue;
    color: black !important;
    font-weight: bold;
    height: 2.5rem;
    border-radius: 0px;
    border: none !important;
  }
  .ant-input {
    background: $color-light-blue;
    color: black !important;
  }
}

@media (max-width: 1000px) {
  .cover-img-login {
    display: none;
  }
}
